<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Page content</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Page content
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                    @click="createPage()"
                    class="mt-1 btn btn-block btn-primary"
                    style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                          label="Name"
                          v-model="search.name"
                          v-on:keyup.enter="searchPages"
                          @input="search.name = $event !== null ? $event : ''"
                          clearable
                          outlined
                          dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <v-select
                          label="Status"
                          v-model="search.is_active"
                          v-on:keyup.enter="searchPages"
                          @input="search.is_active = $event !== null ? $event : ''"
                          :items="status"
                          item-text="name"
                          item-value="value"
                          clearable
                          outlined
                          dense
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="5" class="text-right">
                      <v-btn
                          class="btn btn-primary"
                          style="color: #fff;"
                          @click="searchPages"
                          :loading="loading"
                      >
                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                        Search
                      </v-btn>
                    </v-col>
                  </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('name')" @click="sortBy('name')">Name</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(page, index) in sortedItems" :key="index" >
                    <td class="px-3">
                      <a @click="editPage(page.id)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ page.name }}
                      </a>
                    </td>
                    <td>
                      <span class="badge"
                            v-bind:class="{ 'badge-success': page.is_active, 'badge-danger': !page.is_active }"
                      >{{ page.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editPage(page.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deletePage(page.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="4" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="pages.length > 0"
                  class="pull-right mt-7"
                  @input="getAllPages"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>

          </div>
        </div>
      </div>
    </div>  
  </v-app>
</template>
<script>
import PageService from "@/services/cms/page/PageService";

const page = new PageService();

export default {
  data() {
    return {
      loading: false,
      total: null,
      perPage: null,
      page: null,
      pages: [],
      search:{
        name: '',
        is_active: ''
      },
      sort: {
        key: '',
        isAsc: false
      },
      status: [
        {name: 'Active', value: '1'},
        {name: 'Inactive', value: '0'},
      ],
    }
  },

  mounted(){
    this.getAllPages();
  },
  computed: {
    sortedItems() {
      const list = this.pages.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllPages() {
      page
      .paginate(this.search, this.page)
      .then((response) => {
        this.pages = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      })
      .catch((err) => {
        this.$snotify.error("Oops something went wrong");
      });
    },
    searchPages() {
      this.getAllPages();
    },
    createPage() {
      this.$router.push({
          name: 'page-create'
        }
      );
    },
    editPage(pageId) {
      this.$router.push({
        name: 'page-edit',
          params: {
            pageId: pageId
          }
        }
      );
    },
    deletePage(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
            no: "No",
            yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            page
            .delete(id)
            .then((response) => {
                this.getAllPages()
                this.$snotify.success("Page deleted ");
            })
            .catch((err) => {
                this.$snotify.error("Oops something went wrong");
            });
          }
        }
      });
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>